/* You can add global styles to this file, and also import other style files */
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
.viewContainer { padding-top: 30px; }
.viewPadding { padding: 10px; }

.fill-space {
    flex: 1 1 auto;
}

::ng-deep {
    .multiline-tooltip {
        white-space: pre-line;
    }
}

.fc50 { width: 50px; }
.fc75 { width: 75px; }
.fc100 { width: 100px; }
.fc100P { width: 100%; }
.fc125 { width: 125px; }
.fc150 { width: 150px; }
.fc175 { width: 175px; }
.fc200 { width: 200px; }
.fc250 { width: 250px; }
.fc300 { width: 300px; }
.fc350 { width: 350px; }
.fc400 { width: 400px; }
.fc450 { width: 450px; }
.fc500 { width: 500px; }
.fc700 { width: 700px; }

// .mat-icon {
//     height:18px !important;
//     width:18px !important;
//     font-size:18px !important;
// }

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing.dark-backdrop {
    background: #000;
    opacity: 0.75 !important;
  }


  .timepicker-overlay {
    z-index: 10000000 !important;
}

.timepicker-backdrop-overlay {
    z-index: 10000000 !important;
}

.timepicker {

    .timepicker__header {
      background-color: #6f6f6f!important;
    }
  
    .clock-face__clock-hand {
      background-color: #6f6f6f !important;
  
      &::after {
        background-color: #6f6f6f !important;
      }
  
      &::before {
        border-color: #6f6f6f !important;
      }
    }
  
    .clock-face__number {
      > span.active {
        background-color: #6f6f6f !important;
      }
    }
  
    button:not(.timepicker-dial__item) {
      color: #6f6f6f !important;
    }
  }